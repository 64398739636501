// created by http://mcg.mbitson.com/
/* For use in src/lib/core/theming/_palette.scss */
$md-anaisprimary: (
  50 : #e1e1f1,
  100 : #b3b5db,
  200 : #8184c3,
  300 : #4c5e9c,
  400 : #282d99,
  500 : #020887,
  600 : #02077f,
  700 : #010674,
  800 : #01046a,
  900 : #000059,
  A100 : #8989ff,
  A200 : #5656ff,
  A400 : #2323ff,
  A700 : #0909ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$md-anaisaccent: (
  50 : #f8f3e9,
  100 : #ede1c8,
  200 : #e1cea4,
  300 : #d5ba80,
  400 : #ccab64,
  500 : #c39c49,
  600 : #bd9442,
  700 : #b58a39,
  800 : #ae8031,
  900 : #a16e21,
  A100 : #fff0dc,
  A200 : #ffdba9,
  A400 : #f8cd77,
  A700 : #ffbb5d,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$md-anaiswarn: (
  50 : #e5f4f3,
  100 : #bee4e1,
  200 : #93d3cd,
  300 : #67c1b8,
  400 : #47b3a9,
  500 : #26a69a,
  600 : #229e92,
  700 : #1c9588,
  800 : #178b7e,
  900 : #0d7b6c,
  A100 : #adfff3,
  A200 : #7affec,
  A400 : #47ffe4,
  A700 : #2dffe0,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

