
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
@import "my-classes";
@import "my-theme";


// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$dreamhomeinisrael-client-angular-primary: mat.define-palette($md-anaisprimary, 500, 300, 900);
$dreamhomeinisrael-client-angular-accent: mat.define-palette($md-anaisaccent, 500, A400, 900);

// The warn palette is optional (defaults to red).
$dreamhomeinisrael-client-angular-warn: mat.define-palette($md-anaiswarn, 500, A400, 900);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$dreamhomeinisrael-client-angular-theme: mat.define-light-theme((
  color: (
    primary: $dreamhomeinisrael-client-angular-primary,
    accent: $dreamhomeinisrael-client-angular-accent,
    warn: $dreamhomeinisrael-client-angular-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($dreamhomeinisrael-client-angular-theme);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family:  'Open Sans', serif; }

.mat-bottom-sheet-container {
  background: rgba(1, 1, 1, 0.8) !important;
}

.flex-fields {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  & > * {
    margin: 1em;
    padding: 1em;
  }
}

.my-stepper .mat-horizontal-stepper-header {
  overflow: visible;
  white-space: nowrap;
}

.my-stepper .mat-step-label {
  overflow: visible;
  white-space: nowrap;
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}

.my-stepper .mat-horizontal-stepper-header-container {
  flex-wrap: wrap;
}

.mat-drawer-content {
  overflow: hidden;
  min-height: 100vh;
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
}
