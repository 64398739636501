.bold {
  font-weight: 600;
}

.cursor-pointer {
  cursor: pointer;
}

.bolder {
  font-weight: 800;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.flex-column-center-space-between {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.border-box {
  box-sizing: border-box;
}

.flex-row-wrap-center {
  & > * {
    margin: 0.5em 1em;
  }
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  flex-flow: wrap;
}

.flex-row-center {
  & > * {
    margin: 0.5em 1em;
  }
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

.capital-letters {
  text-transform: uppercase;
}

.text-large {
  font-size: 2em;
}

.text-medium {
  font-size: 1.5em;
}

.max-text-width-large {
  max-width: 900px;
}

.padding-bottom-1 {
  padding-bottom: 1em;
}

.margin-auto {
  margin: auto;
}
